.index_root {
    /* transform: scale(1, 0.85); */
}

.main_root {
    /* transform: scale(0.85, 1); */
}

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
}


.login_root_box {
    width: 100%;
    height: 100%;
    /* background-image: url(../img/login_bg.jpg); */
    background-size: 100% 100%;
}


.login_root_box_in {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_root {
    width: 440px;
    background-color: rgba(255, 255, 255, 0.8);
    padding-bottom: 20px;
    padding-top: 10px;
    border-radius: 10px;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 150px;
}

.login-form-button {
    width: 100%;
}

.router {
    float: right;
    width: calc(100% - 250px);
}

.blue {
    color: rgb(64, 169, 255);
}

.download-table {
    /* width: 125px; */
    /* height: 32px; */
    color: #1890ff;
    font-weight: bold;
    background-color: #ffffff;
    border-color: #1890ff;
    /* text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12); */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
}

.main_title {
    color: #696a6b;
    text-align: center;
    padding: 5px 0;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: bold;
}

/* @media screen and (max-width: 550px) {
    .main_title {
        display: none;
    }
} */

.select_before {
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    font-size: 14px;
    box-sizing: border-box;
    height: 32px;
    line-height: 30px;
    text-indent: 10px;
    border-right: none;
    display: inline-block;
    width: 152px;
}

.down_btn {
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    font-size: 14px;
    box-sizing: border-box;
    height: 32px;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    padding: 0 10px;
}

.explain_div {
    line-height: 25px;
    border-bottom: 2px solid #f5f5f5;
    padding: 3px 0;
}

.nowrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.span_head {
    font-weight: bold;
    color: #5f5f5f;
}

.ant-menu-item a {
    color: #ffffff !important;
}

.item_left_border {
    border-left: 3px solid #1890ff;
    font-weight: bold;
    border-radius: 2px;
    line-height: 17px;
    font-size: 15px;
    text-indent: 4px;
}

.item_left_value {
    font-size: 14px;
    text-indent: 7px;
    margin-top: 4px;
    line-height: 16px;
}

.detail_top_nav {
    height: 45px;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 1000;
    border-bottom: 2px solid #c6c6c6;
    overflow: hidden;
}

.samlp_item {
    box-shadow: 1px 2px 2px 2px rgb(197, 197, 197);
    text-align: center;
    cursor: pointer;
    color: #0e0e0e;
    line-height: 26px;
    width: 110px;
    float: left;
}

.samlp_item_focus {
    box-shadow: 1px 2px 2px 2px #bedffe;
    background-color: #2db7f5;
    color: #ffffff;
    font-weight: bold;
}

.samlp_item_left {
    border-bottom: 1px solid #cfcdcd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.ques_icon {
    margin-left: 3px;
    font-size: 12px;
    cursor: pointer;
    color: #8a8989;
}

.img_camera_box image {
    width: 400px !important;
    height: 300px !important;
}



.amap-copyright {
    opacity: 0 !important;
}

.amap-logo {
    width: 0 !important;
    opacity: 0 !important;
}

#container {
    background-color: #092f66 !important;
    background-image: none;
}

.index_each_box {
    background-color: #092f66;
}

.index_each_box_title {
    background-color: #051d3f;
    color: #28a3ef;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    position: relative;
}

.index_each_box_title a {
    color: #28a3ef;
    position: absolute;
    right: 10px;
}

.each_bg {
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    padding: 10px;
}

.each_bg2 {}

.each_bg3 {}

.each_bg4 {}

.index_city {
    display: inline-block;
    width: 215px;
    text-align: right;
    margin-right: 7px;
}

.chart_style {
    width: 30%;
    margin: 1%;
    float: left;
    height: 300px;
}

.son_table {
    margin: 15px 0;
    /* border: 2px solid #b4b4b4; */
    padding: 20px 20px 20px 0px;
    box-shadow: 2px 2px 3px 3px rgb(197, 197, 197);
}

.son_table tr {
    background-color: #f5f5f5 !important;
}

.son_table th {
    background-color: #f5f5f5 !important;
}




.test {
    /* #edfbff */
    color: #e4e4e4;
    color: #888787;
    color: #0068c9;
    color: #1890ff;
    color: #20a2ff;
    color: #00daea;
    color: #20b759;
    color: #825de0;
    color: #f300d3;
    color: #b03200;
    ;
}